.backgroundContainer {
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -1;
  align-items: center;
}

.infoContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

@media screen and (min-width: 601px) {
  .nameText {
    font: 50px/1.1em "opensans-bold", sans-serif;
    font-weight: bold;
    font-size: 150px;
    color: white;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8);
  }
}

@media screen and (max-width: 600px) {
  .nameText {
    font: 50px/1.1em "opensans-bold", sans-serif;
    font-weight: bold;
    font-size: 15vw;
    color: white;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8);
    max-height: 200px;
  }
}

.descriptionText {
  font: 1.9em "librebaskerville-regular", serif;
  color: #a8a8a8;
  width: 70%;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  font-size: 22px;
  max-width: 1024px;
}

.icon {
  margin: 15px;
  color: white;
}

.icon:hover {
  margin: 15px;
  color: grey;
}

.avatar {
  border-radius: 50%;
}

.avatarContainer {
  padding: 0px 20px;
}
